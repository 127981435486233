import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

import useTempConfig from '@hooks/useTempConfig';
import StyledModal from './ModalDownload.styled';
import { colorTypeProps } from '@config/common-propTypes';

const ModalDownload = ({
  bgColor,
  titleProps,
  bgColorType,
  overlayColor,
  overlayColorType,
  descriptionProps
}) => {
  const [{ modalDownload }, { setModalIsOpen, setModalDownload }] =
    useTempConfig();

  useEffect(() => {
    setModalIsOpen(modalDownload.isOpen);
  }, [setModalIsOpen, modalDownload.isOpen]);

  const resetDownloadModal = () => {
    setModalDownload({
      isOpen: false,
      title: undefined,
      description: undefined
    });
  };

  const width = { xs: '80%', sm: '80%', md: '40%', lg: '40%' };

  return (
    <StyledModal
      autoClose
      width={width}
      color={bgColor}
      colorType={bgColorType}
      colorOverlay={overlayColor}
      onClose={resetDownloadModal}
      isOpen={modalDownload.isOpen}
      colorOverlayType={overlayColorType}
      autoCloseTimeout={modalDownload.duration * 1000}
    >
      <Typography textAlign='center' type='h5' color='primary' {...titleProps}>
        {modalDownload.title}
      </Typography>
      <Typography
        textAlign='center'
        type='paragraph2'
        color='primary'
        {...descriptionProps}
      >
        {modalDownload.description}
      </Typography>
    </StyledModal>
  );
};

ModalDownload.propTypes = {
  bgColor: PropTypes.string,
  bgColorType: colorTypeProps,
  overlayColor: PropTypes.string,
  overlayColorType: colorTypeProps,
  titleProps: PropTypes.shape(Typography.propTypes),
  descriptionProps: PropTypes.shape(Typography.propTypes)
};

ModalDownload.defaultProps = {
  titleProps: {},
  bgColor: 'download',
  bgColorType: 'dark',
  descriptionProps: {},
  overlayColor: 'overlayDark',
  overlayColorType: 'normal'
};

export default ModalDownload;
