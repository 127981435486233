/* istanbul ignore file */
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body {
    font-size: ${({ fontSize, theme }) => theme.font.size[fontSize]};
    ${({ modalIsOpen }) => {
      return modalIsOpen ? 'overflow: hidden !important;' : '';
    }}
  }
`;
