import Router from 'next/router';
import { useRef, useEffect } from 'react';

const useScrollRestoration = (router, dontRestore) => {
  const copyDontRestore = useRef(false);

  const restoreScrollPos = () => {
    if (!dontRestore && !copyDontRestore.current) {
      window.scrollTo(0, 0);
    }
    if (!copyDontRestore.current) {
      copyDontRestore.current = false;
    }
  };

  useEffect(() => {
    if (dontRestore) {
      copyDontRestore.current = true;
    }
  }, [dontRestore]);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      let shouldScrollRestore = false;
      window.history.scrollRestoration = 'manual';
      restoreScrollPos();

      const onBeforeUnload = (event) => {
        delete event.returnValue;
      };

      const onRouteChangeComplete = () => {
        if (shouldScrollRestore) {
          shouldScrollRestore = false;
          restoreScrollPos();
        }
      };

      window.addEventListener('beforeunload', onBeforeUnload);
      Router.events.on('routeChangeComplete', onRouteChangeComplete);
      Router.beforePopState(() => {
        shouldScrollRestore = true;
        return true;
      });

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
        Router.events.off('routeChangeComplete', onRouteChangeComplete);
        Router.beforePopState(() => true);
      };
    }
  }, [router]);
};

export default useScrollRestoration;
