import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  RichText,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import {
  StyledModal,
  StyledButton,
  StyledScrollbar,
  StyledLogo,
  StyledButtonContainer,
  StyledLogoInherit
} from './ModalInactive.styled';
import { colorTypeProps } from '@config/common-propTypes';
import useModalInactive from '@hooks/useModalInactive';
import { stopTimer } from '@utils/session';

const ModalInactive = ({
  bgColor,
  modalDataInactive,
  titleProps,
  bgColorType,
  overlayColor,
  overlayColorType,
  descriptionProps
}) => {
  const [
    {
      modalInactive: { isOpen }
    },
    { setModalIsOpenInactive, setModalInactive }
  ] = useModalInactive();

  const data = {
    title: modalDataInactive?.title,
    description: modalDataInactive?.description,
    button: {
      text: modalDataInactive.buttons[0]?.text || 'Aceptar'
    },
    imagen: modalDataInactive?.imagen || ''
  };

  useEffect(() => {
    setModalIsOpenInactive(isOpen);
  }, [setModalIsOpenInactive, isOpen]);

  const resetRedirectModal = () => {
    setModalInactive({
      isOpen: false
    });
  };

  const width = { xs: '80%', sm: '80%', md: '40%', lg: '40%' };

  return data?.description ? (
    <StyledModal
      width={width}
      color={bgColor}
      colorType={bgColorType}
      colorOverlay={overlayColor}
      onClose={resetRedirectModal}
      isOpen={isOpen}
      colorOverlayType={overlayColorType}
    >
      <StyledLogo>
        <StyledLogoInherit>
          <img alt='sinfoto' src={data?.imagen?.icon?.url} />
        </StyledLogoInherit>
      </StyledLogo>
      <Typography textAlign='center' type='h5' color='primary' {...titleProps}>
        {data.title}
      </Typography>
      <StyledScrollbar>
        <RichText
          textAlign='center'
          type='paragraph2'
          color='primary'
          {...descriptionProps}
          dangerouslySetInnerHTML={{
            __html: data.description?.replace(/\\n/g, '<br />')
          }}
        />
        <StyledButtonContainer>
          {data.button.text && (
            <StyledButton
              fullWidth={false}
              onClick={() => {
                resetRedirectModal();
                stopTimer();
              }}
            >
              {data.button.text}
            </StyledButton>
          )}
        </StyledButtonContainer>
      </StyledScrollbar>
    </StyledModal>
  ) : null;
};

ModalInactive.propTypes = {
  bgColor: PropTypes.string,
  bgColorType: colorTypeProps,
  overlayColor: PropTypes.string,
  overlayColorType: colorTypeProps,
  titleProps: PropTypes.shape(Typography.propTypes),
  descriptionProps: PropTypes.shape(Typography.propTypes),
  modalDataInactive: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
      })
    )
  })
};

ModalInactive.defaultProps = {
  titleProps: {},
  bgColor: 'download',
  bgColorType: 'dark',
  descriptionProps: {},
  overlayColor: 'overlayDark',
  overlayColorType: 'normal',
  modalDataInactive: {
    title: undefined,
    subtitle: undefined,
    description: undefined,
    buttons: [{ text: undefined }]
  }
};

export default ModalInactive;
