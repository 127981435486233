import { combineReducers } from 'redux';

import { reducer as configReducer } from './config';
import { reducer as tempConfigReducer } from './tempConfig';
import { reducer as authReducer } from './auth';
import { reducer as modalInactive } from './modalInactive';

const rootReducer = combineReducers({
  config: configReducer,
  tempConfig: tempConfigReducer,
  auth: authReducer,
  inactiveModal: modalInactive
});

export default rootReducer;
