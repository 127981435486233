import { spacing } from '@config/styles/core/utils';
import breakpoints from '@config/styles/core/breakpoints';
import { fontSizeValues, breakpointsValues } from '@config/constants';

export default {
  font: {
    family: {
      primary: "'Nunito', sans-serif"
    },
    size: {
      big: '10rem',
      h1: '3.75rem',
      h1RvN3D: '1.875rem',
      h1RvN3M: '1.625rem',
      h2: '3rem',
      h3: '2.5rem',
      h4: '2.25rem',
      h5: '1.625rem',
      h6: '1.5rem',
      subtitle1: '1.1375rem',
      subtitle2: '1.25rem',
      subtitle2RvN3D: '1.25rem',
      subtitle2RvN3M: '0.875rem',
      subtitle3: '1.375rem',
      paragraph1: '1.125rem',
      paragraph2: '1rem',
      paragraph3: '0.875rem',
      caption: '0.75rem',
      help: '0.625em',
      small: '0.5rem',
      sm: `${fontSizeValues.SM}px`,
      md: `${fontSizeValues.MD}px`,
      lg: `${fontSizeValues.LG}px`
    },
    letterSpacing: {
      small: '-0.03125rem',
      medium: '0.25rem',
      large: '0.375rem',
      normal: '0rem'
    },
    lineHeight: {
      small: '1.8rem',
      medium: '2.5rem',
      large: '3.5rem',
      normal: 'normal',
      caption: '0.95rem',
      caption2: '1.375rem',
      paragraph3: '1.125rem'
    }
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    linear: {
      normal: 'linear'
    },
    duration: {
      shortest: '150ms',
      shorter: '200ms',
      short: '250ms',
      standard: '300ms',
      complex: '375ms',
      enteringScreen: '225ms',
      leavingScreen: '195ms'
    }
  },
  zIndex: {
    initial: 0,
    backward: -1,
    forward: 1,
    fixed: 2,
    higher: 3,
    super: 100
  },
  borderRadius: {
    none: 'unset',
    button: '100px',
    small: '3px',
    medium: '8px',
    large: '18px',
    total: '50%',
    quickLinks: ' 10px 0 0 10px'
  },
  tooltipSize: {
    small: '10em',
    medium: '20em',
    large: '30em'
  },
  containerWidth: {
    xs: { width: '92%', maxWidth: '92%' },
    sm: { width: '85%', maxWidth: '85%' },
    md: { width: '85%', maxWidth: '85%' },
    lg: { width: '75%', maxWidth: '67.5em' },
    fluid: { width: '100%', maxWidth: '120em' }
  },
  utils: {
    spacing
  },
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 0, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    breakpoints: {
      xs: breakpointsValues.XS,
      sm: breakpointsValues.SM,
      md: breakpointsValues.MD,
      lg: breakpointsValues.LG
    }
  },
  breakpoints
};
