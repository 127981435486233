/* eslint-disable react/forbid-prop-types */
import Router from 'next/router';
import PropTypes from 'prop-types';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from 'styled-components';
import { useStore, useSelector, useDispatch } from 'react-redux';
import { useRef, useState, useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';

import 'nprogress/nprogress.css';
import '@bvcco/bvc-digital-market-component-library/dist/bundle.css';

import {
  types,
  sockets,
  cmsLocales,
  localesToLanguages
} from '@config/constants';
import App from '@config/app';
import wrapper from '@redux/store';
import GTMPageView from '@utils/gtm';
import Seo from '@components/Seo/Seo';
import i18n from '@config/translations';
import useRouter from '@hooks/useRouter';
import useConfig from '@hooks/useConfig';
import { isEquals } from '@utils/strings';
import { isAnArray } from '@utils/arrays';
import themes from '@config/styles/themes';
import keyedRoutes from '../../keyed-routes';
import useSocketIo from '@hooks/useSocketIO';
import Modals from '@components/Modals/Modals';
import useTempConfig from '@hooks/useTempConfig';
import numeralConfig from '@config/numeralConfig';
import tokenGenerator from '@utils/tokenGenerator';
import { logPageView } from '@config/lib/analytics';
import GlobalStyle from '@styled-pages/_app.styled';
import BaseStyles from '@config/styles/core/BaseStyles';
import {
  setCookie,
  deleteCookie,
  getLocalToken,
  getCookies
} from '@utils/cookies';
import useScrollRestoration from '@hooks/useScrollRestoration';
import useModalInactive from '@hooks/useModalInactive';
import changeAuthActions from '@redux/reducers/auth';
import {
  removeAllListener,
  setupListenerInactivity,
  setupListenerLogout,
  stopTimer,
  validateSession
} from '@utils/session';
import app from 'next/app';
/**
 * track route changes for google analytics
 */
Router.events.on('routeChangeComplete', () => logPageView());

const RootComponent = ({ Component, pageProps, tokenLocal }) => {
  const { cms } = App.getInstance();
  const store = useStore();
  const router = useRouter();
  const isFirstLoad = useRef(true);
  const [slugs, setSlugs] = useState();
  const [modalData, setModal] = useState();
  const persistor = persistStore(store);
  const [{ fontSize, theme, language }, { toggleLanguage, toggleTheme }] =
    useConfig();
  const rehydrated = useSelector(({ _persist }) => _persist.rehydrated);
  const selectedTheme = themes.find(({ name }) => name === theme);
  const [{ modalDownload, modalIsOpen }] = useTempConfig();
  const [currentPage, setCurrentPage] = useState();
  const [user, setUser] = useState(null);
  const [, { socket }] = useSocketIo({
    config: sockets.authenticate[types.RV]
  });
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const { user: userLogged } = useSelector(({ auth }) => auth);
  const [modalDataInactive, setModalInactivity] = useState();

  useEffect(() => {
    const generateToken = async () => {
      setToken(await tokenGenerator());
    };
    generateToken();
    // TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER });
  }, []);

  useEffect(() => {
    if (socket && token) {
      socket.emit(sockets.authenticate.eventName, { token });
    }
  }, [socket, token]);

  useEffect(() => {
    const handleRouteChange = (url) => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useScrollRestoration(router, modalDownload.isOpen);

  useEffect(() => {
    const fetchData = async () => {
      const response = await cms.getSlugs({ isCanvas: true });
      if (response) {
        setSlugs([...response, ...keyedRoutes]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (slugs) {
      let path = router.query?.slug || router.route;
      if (isAnArray(path)) {
        path = `/${path[0]}`;
      }
      const tempCurrentPage = slugs.find((page) => isEquals(page.slug, path));
      if (currentPage?.slug === path || tempCurrentPage?.slug === path)
        setCurrentPage(tempCurrentPage);
    }
  }, [router.query, router.route]);

  useEffect(() => {
    if (slugs) {
      let path = router.query?.slug || router.route;
      if (router.query?.slug && isAnArray(path)) {
        path = path.join('/');
      }
      const tempCurrentPage = slugs.find((page) =>
        isEquals(page.slug, router.query?.slug ? `/${path}` : path)
      );

      setCurrentPage(tempCurrentPage);
      const locale = localesToLanguages[tempCurrentPage?.locale];
      if (rehydrated && locale && language !== locale) toggleLanguage();
    }
  }, [slugs]);

  useEffect(() => {
    if (!language) return;
    const fetchModal = async () => {
      const response = await cms.getModal(cmsLocales[language], {
        identifier: 'redirect'
      });
      const responseInactive = await cms.getModal(cmsLocales[language], {
        identifier: 'inactivity'
      });
      setModal(response);
      setModalInactivity(responseInactive);
    };
    fetchModal();
    if (currentPage) {
      const translatedPage = slugs.find(
        (page) =>
          isEquals(page.id, currentPage.id) &&
          isEquals(page.locale, cmsLocales[language])
      );
      if (
        translatedPage &&
        router.pathname !== '/' &&
        translatedPage?.slug !== '/'
      ) {
        router.replace(
          {
            pathname: translatedPage.slug,
            query: router.query
          },
          translatedPage.slug
        );
      } else if (currentPage !== null && !translatedPage) {
        setCurrentPage(null);
        router.push('/500');
      }
    }
  }, [language]);

  useEffect(() => {
    if (!language) return;
    if (!isFirstLoad.current) {
      i18n.changeLanguage(language);
    } else {
      isFirstLoad.current = false;
    }
    deleteCookie('language');
    setCookie('language', language);
  }, [language]);

  if (isFirstLoad.current) {
    App.boot();
    numeralConfig.init();
  }
  useEffect(() => {
    if (
      !router?.pathname.includes('mercado-local-en-linea') &&
      !router?.pathname.includes('local-market-online') &&
      router?.pathname !== '/'
    ) {
      if (theme === 'LIGHT') {
        toggleTheme();
        localStorage.setItem('THEME_DEFAULT', 'LIGHT');
        localStorage.setItem('prevPag', router?.pathname);
      }
    } else if (
      localStorage.getItem('THEME_DEFAULT') !== theme &&
      localStorage.getItem('THEME_DEFAULT') &&
      localStorage.getItem('prevPag')
    ) {
      toggleTheme();
      localStorage.removeItem('prevPag');
    }
  }, [router]);

  const [{ modalInactive }, { setModalIsOpenInactive, setModalInactive }] =
    useModalInactive();

  useEffect(() => {
    if (user) {
      setupListenerInactivity(
        setModalIsOpenInactive,
        setModalInactive,
        modalInactive,
        dispatch,
        changeAuthActions,
        setUser
      );
      if (user?.expiresIn) {
        setupListenerLogout(user?.expiresIn, dispatch, changeAuthActions);
      }
    } else {
      removeAllListener();
      stopTimer();
    }
  }, [user]);
  useEffect(() => {
    if (!userLogged) {
      stopTimer();
    }
  }, [userLogged]);

  useEffect(() => {
    const context = { req: { headers: { cookie: '_bvcSessionUser' } } };
    const { _bvcSessionUser } = getCookies(context);
    const sessionUser = _bvcSessionUser;
    if (sessionUser) {
      userLoggedValid();
    } else {
      dispatch(changeAuthActions.loadingAuth(false));
    }
    async function userLoggedValid() {
      const userLog = await validateSession(
        sessionUser,
        process.env.NEXT_PUBLIC_BVC_BASE_VALIDATE_SESSION,
        process.env.NEXT_PUBLIC_DOMAIN
      );
      if (userLog) {
        dispatch(
          changeAuthActions.changeAuth({ ...userLog, token: sessionUser })
        );
        setUser(userLog);
      }
      dispatch(changeAuthActions.loadingAuth(false));
    }
  }, [dispatch]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      {() => (
        <ThemeProvider theme={selectedTheme}>
          <Seo />
          <GlobalStyle fontSize={fontSize} modalIsOpen={modalIsOpen} />
          <BaseStyles />
          <Component {...pageProps} />
          <Modals modalData={modalData} modalDataInactive={modalDataInactive} />
        </ThemeProvider>
      )}
    </PersistGate>
  );
};

RootComponent.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.any.isRequired,
  tokenLocal: PropTypes.any.isRequired
};

RootComponent.getInitialProps = async (appContext) => {
  const appProps = await app.getInitialProps(appContext);
  const tokenLocal = getLocalToken(appContext.ctx);
  return { ...appProps, tokenLocal };
};

export default wrapper.withRedux(RootComponent);
