export default {
  black: {
    dark: '#000000',
    light: '#2c3740',
    normal: '#02111A',
    altLight: '#404a53'
  },
  blackTransparent: {
    dark: 'rgba(0, 0, 0, 0.25)',
    light: 'rgba(0, 0, 0, 0.50)',
    normal: 'rgba(2, 17, 26, 0.7)'
  },
  blueTransparent: {
    dark: 'rgba(1,21,54,0.1)',
    light: 'rgba(1,21,54,0.1)',
    normal: 'rgba(1,21,54,0.1)'
  },
  darkGrayHardTransparent: {
    dark: 'rgb(29, 37, 45, 0)',
    light: 'rgb(91, 103, 112, 0)',
    normal: 'rgb(51, 63, 72, 0)'
  },
  blue: {
    dark: '#5B93DB',
    light: '#8BD3E6',
    normal: '#00B5E2'
  },
  blueFilters: {
    dark: '#2C5697',
    light: '#00B5E2',
    normal: '#003C71'
  },
  cornflowerBlue: {
    dark: '#013C60',
    light: '#013C60',
    normal: '#013C60'
  },
  darkBlueTransparent: {
    dark: 'rgba(4,28,44,0.7)',
    light: 'rgba(44,86,151,0.7)',
    normal: 'rgba(0,60,113,0.7)'
  },
  darkBlue: {
    dark: '#041C2C',
    light: '#2C5697',
    normal: '#003C71'
  },
  lightBlue: {
    dark: '#DBE2E9',
    light: '#DBE2E9',
    normal: '#DBE2E9'
  },
  gray: {
    dark: '#7C878E',
    light: '#C1C6C8',
    normal: '#A2AAAD'
  },
  blueGray: {
    dark: '#3E4445',
    light: '#F0F6F7',
    normal: '#F0F6F7',
    background: '#232D36'
  },
  darkGray: {
    dark: '#1D252D',
    light: '#5B6770',
    normal: '#333F48'
  },
  lightGray: {
    dark: '#D9D9D6',
    light: '#F0F6F7',
    normal: '#EFEFEF'
  },
  darkGrayTransparent: {
    dark: 'rgba(29, 37, 45, 0.6)',
    light: 'rgba(91, 103, 112,0.6)',
    normal: 'rgba(51, 63, 72, 0.6)'
  },
  lightGrayTransparent: {
    dark: 'rgba(162, 170, 173, 0.3)',
    light: 'rgba(162, 170, 173, 0.75)',
    normal: 'rgba(162, 170, 173, 0.5)'
  },
  transparentGray: {
    dark: 'rgba(4, 28, 44, 0.7)',
    light: 'rgba(187, 184, 184, 0.1)',
    normal: 'rgba(187, 184, 184, 0.5)'
  },
  darkGrayOpaque: {
    dark: '#3F5661',
    normal: '#3F5661',
    light: '#3F5661'
  },
  green: {
    dark: '#3f9d39',
    light: '#a6ff97',
    normal: '#73CF67'
  },
  greenTransparent: {
    dark: 'rgba(115, 207, 103, 0.4)',
    light: 'rgba(115, 207, 103, 0.4)',
    normal: 'rgba(115, 207, 103, 0.4)'
  },
  red: {
    dark: '#b63b3d',
    light: '#ff9d96',
    normal: '#ED6C68'
  },
  transparentRed: {
    dark: 'rgba(182, 59, 61, 0.1)',
    light: 'rgba(255, 157, 0.1)',
    normal: 'rgba(237, 108, 104, 0.1)'
  },
  yellow: {
    dark: '#A28700',
    light: '#ffff61',
    normal: '#FDDA25'
  },
  transparent: {
    dark: 'rgba(0,0,0,0)',
    light: 'rgba(0,0,0,0)',
    normal: 'rgba(0,0,0,0)'
  },
  white: {
    dark: '#F9F9F9',
    light: '#ffffff',
    normal: '#ffffff',
    disabled: '#5b6770'
  },
  mercury: {
    dark: '#E5E5E5',
    light: '#E5E5E5',
    normal: '#E5E5E5'
  },
  white3: {
    light: '#EFEFEF'
  },
  hoverTable: {
    light: '#D9D9D9'
  },
  darkSelect: {
    dark: '#5B6770',
    light: '#5B6770',
    normal: '#5B6770'
  },
  lightTextPrimary: {
    dark: '#003C71',
    light: '#003C71',
    normal: '#003C71'
  },
  collapse: {
    light: '#F9F9F9',
    dark: '#F9F9F9',
    normal: '#F9F9F9'
  },
  exchange: {
    light: '#7C878E',
    dark: '#7C878E',
    normal: '#7C878E'
  },
  grayProfile: {
    normal: '#404a53'
  }
};
