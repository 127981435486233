import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import modalInactiveActions from '@redux/reducers/modalInactive';

const useModalInactive = () => {
  const dispatch = useDispatch();
  const { modalIsOpen, modalInactive } = useSelector(
    ({ inactiveModal }) => inactiveModal
  );

  const setModalIsOpenInactive = useCallback(
    (value) => dispatch(modalInactiveActions.setModalIsOpenInactive(value)),
    [dispatch]
  );

  const setModalInactive = useCallback(
    (value) => dispatch(modalInactiveActions.setModalInactive(value)),
    [dispatch]
  );

  return [
    { modalIsOpen, modalInactive },
    { setModalIsOpenInactive, setModalInactive }
  ];
};

export default useModalInactive;
