import styled from 'styled-components';
import {
  Modal,
  Button,
  Scrollbar
} from '@bvcco/bvc-digital-market-component-library';

export const StyledModal = styled(Modal)`
  .typography:first-of-type {
    margin: ${({ theme }) => theme.utils.spacing(1, 0, 4)};
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  & img {
    margin-bottom: 5px;
  }
`;

export const StyledButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.utils.spacing(4)};
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  width: 150px;
  border: 0px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.btnMk2Footer.light};
  }
`;

export const StyledScrollbar = styled(Scrollbar)`
  text-align: center;
  & .scrollbar-wrapper {
    max-height: ${({ theme }) => theme.utils.spacing(35)};
    padding: ${({ theme }) => theme.utils.spacing(0, 5)};
  }
`;
