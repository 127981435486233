/* istanbul ignore file */
/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
import createSagaMiddleware from 'redux-saga';
import configureStore from 'redux-mock-store';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';

import rootSaga from './sagas';
import rootReducer from './reducers';

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['news', 'config']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const newStore = createStore(
    persistedReducer,
    initialState,
    bindMiddleware([sagaMiddleware])
  );

  newStore.sagaTask = sagaMiddleware.run(rootSaga);
  return newStore;
};

export const makeMockedStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const mockStore = configureStore([sagaMiddleware]);
  const newStore = mockStore(initialState);
  newStore.sagaTask = sagaMiddleware.run(rootSaga);
  return newStore;
};

export default createWrapper(makeStore);
