import Download from './ModalDownload/ModalDownload';
import ModalInactive from './ModalInactive/ModalInactive';
import Redirect from './ModalRedirect/ModalRedirect';

const Modals = (props) => {
  return (
    <>
      <Download {...props} />
      <Redirect {...props} />
      <ModalInactive {...props} />
    </>
  );
};

export default Modals;
