import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  RichText,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import {
  StyledModal,
  StyledButton,
  StyledScrollbar,
  StyledLogo,
  StyledButtonContainer
} from './ModalRedirect.styled';
import useTempConfig from '@hooks/useTempConfig';
import { colorTypeProps } from '@config/common-propTypes';

const ModalRedirect = ({
  bgColor,
  modalData,
  titleProps,
  bgColorType,
  overlayColor,
  overlayColorType,
  descriptionProps
}) => {
  const [
    {
      modalRedirect: { isOpen, title, action, button, description }
    },
    { setModalIsOpen, setModalRedirect }
  ] = useTempConfig();

  const data = {
    title: title || modalData.title,
    description: description || modalData.description,
    button: {
      text: button?.text || modalData.buttons[0].text
    },
    imagen: modalData.imagen
  };
  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [setModalIsOpen, isOpen]);

  useEffect(() => {
    if (isOpen) {
      let item1 = document.getElementsByClassName('scrollbar-container');
      let item2 = document.getElementsByClassName('ps__thumb-y');
      for (let i = 0; i < item1.length; i++) {
        item1[i].className += ' ps--active-y';
      }
      for (let j = 0; j < item2.length; j++) {
        item2[j].style.height = '85px';
      }
    }
  });

  const resetRedirectModal = () => {
    setModalRedirect({
      isOpen: false
    });
  };

  const width = { xs: '80%', sm: '80%', md: '40%', lg: '40%' };

  return (
    <StyledModal
      width={width}
      color={bgColor}
      colorType={bgColorType}
      colorOverlay={overlayColor}
      onClose={resetRedirectModal}
      isOpen={isOpen}
      colorOverlayType={overlayColorType}
    >
      <StyledLogo>
        <img src={data?.imagen?.icon?.url}></img>
      </StyledLogo>
      <Typography textAlign='center' type='h5' color='primary' {...titleProps}>
        {data.title}
      </Typography>
      <StyledScrollbar>
        <RichText
          textAlign='center'
          type='paragraph2'
          color='primary'
          {...descriptionProps}
          dangerouslySetInnerHTML={{
            __html: data.description?.replace(/\\n/g, '<br />')
          }}
        />
        <StyledButtonContainer>
          <StyledButton
            fullWidth={false}
            onClick={() => {
              resetRedirectModal();
              action();
            }}
          >
            {data.button.text}
          </StyledButton>
        </StyledButtonContainer>
      </StyledScrollbar>
    </StyledModal>
  );
};

ModalRedirect.propTypes = {
  bgColor: PropTypes.string,
  bgColorType: colorTypeProps,
  overlayColor: PropTypes.string,
  overlayColorType: colorTypeProps,
  titleProps: PropTypes.shape(Typography.propTypes),
  descriptionProps: PropTypes.shape(Typography.propTypes),
  modalData: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
      })
    )
  })
};

ModalRedirect.defaultProps = {
  titleProps: {},
  bgColor: 'download',
  bgColorType: 'dark',
  descriptionProps: {},
  overlayColor: 'overlayDark',
  overlayColorType: 'normal',
  modalData: {
    title: undefined,
    subtitle: undefined,
    description: undefined,
    buttons: [{ text: undefined }]
  }
};

export default ModalRedirect;
