import numeral from 'numeral';

const init = () => {
  if (!numeral.locales.es) {
    numeral.register('locale', 'es', {
      delimiters: {
        thousands: '.',
        decimal: '.'
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal: () => '.',
      currency: {
        symbol: '$'
      }
    });
  }

  numeral.locale('es');
};

export default {
  init
};
