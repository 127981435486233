import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setModalIsOpenInactive: ['modalIsOpenInactive'],
  setModalInactive: ['modalInactive']
});

export default Creators;

export const INITIAL_STATE = {
  modalIsOpenInactive: false,
  modalInactive: {
    isOpen: false,
    title: 'Inactividad',
    description: 'Inactive user',
    button: { text: 'Aceptar' },
    action: () => {}
  }
};

export const setModalIsOpenInactive = (state, { modalIsOpenInactive }) => ({
  ...state,
  modalIsOpenInactive
});

export const setModalInactive = (state, { modalInactive }) => ({
  ...state,
  modalInactive
});

export const HANDLERS = {
  [Types.SET_MODAL_IS_OPEN_INACTIVE]: setModalIsOpenInactive,
  [Types.SET_MODAL_INACTIVE]: setModalInactive
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
