import base from './base';
import { themes } from '@config/constants';
import colors from '@config/styles/core/colors';
import shadows from '@config/styles/core/shadows';

export default {
  ...base,
  name: themes.LIGHT,
  colors: {
    tooltip: {
      containerColor: colors.darkGray.normal,
      containerColorMobile: colors.darkGray.dark,
      tooltipContainerColor: colors.darkGray.dark,
      customBtnColor: colors.white.light,
      labelColor: colors.white.light,
      loginBtnBackColor: colors.blue.normal,
      loginBtnColor: colors.white.light,
      closeBtnColor: colors.gray.normal
    },
    userBar: {
      containerColor: colors.blue.dark
    },
    verticalBar: {
      color: colors.gray.dark
    },
    disableDate: {
      light: colors.darkGray.light
    },
    backgroundVolume: {
      normal: colors.white3.light
    },
    separator: {
      primary: colors.lightGray,
      tickerSeparator: colors.lightGray.dark,
      details: colors.black,
      secondary: colors.darkBlue,
      tertiary: colors.gray,
      calendar: {
        dark: colors.lightGray.normal,
        light: colors.lightGray.normal,
        normal: colors.lightGray.normal
      },
      quaternary: colors.darkBlue,
      stickyMenu: colors.blue,
      megaMenuChild: colors.blue,
      megaMenu: {
        dark: colors.lightGray.normal,
        light: colors.lightGray.normal,
        normal: colors.lightGray.normal
      },
      transparent: colors.transparent
    },
    border: {
      danger: colors.red,
      primary: colors.blue,
      secondary: colors.green,
      tertiary: colors.darkBlue,
      active: colors.green,
      quaternary: colors.lightGray,
      foreignExchange: colors.darkGray,
      tabs: {
        dark: colors.lightGray.dark,
        light: colors.lightGray.dark,
        normal: colors.lightGray.dark
      },
      transparent: colors.transparent,
      disabled: colors.gray,
      download: colors.darkGray,
      contact: colors.white,
      divider: colors.darkGray,
      tableBorder: colors.blue,
      quinary: colors.darkBlue
    },
    bg: {
      profile: colors.grayProfile, // FIXME: in light mode
      profileBg: colors.black.altLight,
      datePickerN3: colors.darkGray,
      priceAndVolume: colors.collapse,
      disabledButtonHistorical: colors.darkGray,
      checkbox: colors.darkGray,
      datePicker: colors.greenTransparent,
      datePickerModal: colors.darkBlue,
      buttonLogin: colors.white,
      login: colors.white,
      footerSpacer: colors.gray.dark,
      contactUs: colors.darkBlue,
      certifications: colors.blueGray.background,
      buttonDownload: {
        normal: colors.darkBlue.light,
        light: colors.darkBlue.light,
        dark: colors.darkBlue.light
      },
      download: colors.darkBlue,
      foreignExchange: {
        normal: colors.gray.dark,
        dark: colors.white.dark,
        light: colors.white.dark
      },
      background: colors.white,
      bvcStock: colors.darkGray,
      accessibilityBar: colors.darkGray,
      histogramChartForeignExchange: colors.gray,
      disabled: colors.gray,
      footer: colors.darkGray,
      footerText: colors.white,
      quinary: colors.collapse,
      primary: colors.blue,
      quickLinks: colors.darkBlue,
      megaMenu: colors.lightGray,
      datePickerDisabled: colors.lightGray,
      megaMenuIcons: colors.darkBlue,
      secondary: colors.green,
      danger: colors.red,
      transparentDanger: colors.transparentRed,
      success: colors.green,
      active: colors.green,
      quickLink: colors.darkBlueTransparent,
      transparent: colors.transparent,
      quaternary: colors.blue,
      tickerHeader: {
        dark: colors.darkGray.dark,
        light: colors.darkGray.dark,
        normal: colors.darkGray.dark
      },
      tickerBody: {
        dark: colors.lightGray.normal,
        light: colors.lightGray.normal,
        normal: colors.lightGray.normal
      },
      tickerShadow: colors.darkGrayHardTransparent,
      calendar: {
        dark: colors.white.dark,
        normal: colors.lightGray.normal,
        light: colors.white.light
      },
      overlay: {
        dark: colors.darkBlueTransparent.dark,
        light: colors.darkBlueTransparent.light,
        normal: colors.darkBlueTransparent.normal
      },
      overlayDark: colors.blackTransparent,
      overlayLogin: colors.darkGrayTransparent,
      imageCard: colors.blueFilters,
      imageCardBase: colors.cornflowerBlue,
      event: colors.white,
      imageCardSecondary: colors.cornflowerBlue,
      imageCardTertiary: colors.darkBlue,
      serviceCard: {
        dark: colors.darkBlue.dark,
        light: colors.white.dark,
        normal: colors.white.dark
      },
      localMarket: colors.white,
      chartTooltip: colors.mercury,
      histogramChart: colors.lightGrayTransparent,
      filters: colors.darkBlue,
      filterContainer: colors.white,
      tableHeader: colors.darkGray,
      newsletter: colors.darkBlue,
      operationalEmergingRisks: colors.darkBlue,
      simpleBannerFilter: {
        dark: colors.blue.dark,
        normal: colors.darkBlue.normal,
        light: colors.darkBlue.light
      },
      simpleBannerButton: colors.white,
      hovers: {
        buttonAccessibilityBar: colors.darkBlue,
        quinary: colors.darkBlue,
        tablesRow: colors.hoverTable.light,
        btnMk2Footer: colors.darkBlue
      },
      tertiary: colors.black,
      btnMk2Footer: colors.darkBlue
    },
    font: {
      ticker: colors.darkGray,
      charTooltipValue: {
        dark: colors.darkGray.normal,
        light: colors.darkGray.normal,
        normal: colors.darkGray.normal
      },
      primary: colors.white,
      primaryText: colors.darkSelect,
      primaryTextBlue: colors.lightTextPrimary,
      primaryButton: colors.darkGray,
      secondary: colors.blue,
      secondaryChar: {
        dark: colors.blue.normal,
        light: colors.blue.normal,
        normal: colors.blue.normal
      },
      tertiary: colors.darkBlue,
      active: colors.green,
      danger: colors.red,
      success: colors.green,
      infoTabs: colors.darkBlue,
      disabled: colors.white,
      disabledcalendar: colors.exchange,
      info: colors.darkGray,
      infoGeneral: {
        dark: colors.darkGray.light,
        light: colors.darkGray.dark,
        normal: colors.gray.dark
      },
      infoFilter: colors.gray,
      calendar: colors.darkBlue,
      operationDerivatives: colors.lightGray,
      priceAndVolume: colors.lightTextPrimary,
      contact: colors.lightGray,
      transparent: colors.transparent,
      datePickerDisabled: colors.darkGray,
      stepByStepText: colors.lightBlue,
      megaMenu: {
        item: colors.darkBlue.normal,
        child: colors.darkBlue.normal,
        mobile: colors.darkBlue.normal,
        mobileLink: colors.white.normal
      },
      login: colors.darkBlue,
      loginText: colors.darkGray,
      loginTitle: colors.darkBlue,
      serviceCard: {
        dark: colors.darkGray.light,
        light: colors.darkGray.light,
        normal: colors.darkGray.light
      },
      quaternary: colors.darkGray,
      news: colors.darkGray,
      newsGeneralInfo: colors.darkBlue,
      recharts: {
        dark: colors.gray.dark,
        normal: colors.gray.dark,
        light: colors.gray.dark
      },
      richText: {
        light: colors.darkBlue.light,
        normal: colors.green.normal
      },
      button: colors.blue
    },
    // Charts
    chart: {
      layout: {
        backgroundColor: colors.transparent.normal,
        fontFamily: base.font.family.primary,
        textColor: colors.gray.dark
      },
      grid: {
        vertLines: {
          color: colors.lightGrayTransparent.dark,
          visible: true
        },
        horzLines: {
          color: colors.lightGrayTransparent.dark,
          visible: true
        }
      },
      priceScale: {
        borderColor: colors.blue.light
      },
      timeScale: {
        borderColor: colors.blue.light
      },
      crosshair: {
        vertLine: {
          labelVisible: false
        },
        horzLine: {
          color: colors.red.normal,
          width: 2,
          style: 0
        }
      }
    },
    series: {
      topColor: colors.transparent.normal,
      bottomColor: colors.transparent.normal,
      lineColor: colors.blue.normal,
      crosshairMarkerRadius: 8,
      priceLineVisible: false,
      lastValueVisible: false,
      priceLineStyle: 0,
      priceLineColor: colors.red.normal,
      priceLineWidth: 1,
      lineWidth: 2
    },
    recharts: {
      axisColor: colors.blue.normal,
      barColor: colors.darkGrayOpaque.normal
    },
    hovers: {
      rectangularNovelty: colors.blue.normal,
      socialNetworks: colors.darkBlue.light,
      brands: colors.darkBlue.light
    }
  },
  boxShadow: {
    primary: shadows.gray,
    calendar: shadows.mercury,
    secondary: shadows.blackTransparent,
    tertiary: shadows.blueTransparent,
    autoComplete: shadows.autoComplete
  }
};
