import colors from './colors';

export default {
  gray: {
    dark: `0px 2px 4px ${colors.lightGray.dark}`,
    light: `0px 2px 4px ${colors.lightGray.light}`,
    normal: `0px 2px 4px ${colors.lightGray.normal}`
  },
  blackTransparent: {
    dark: `-4px 4px 4px ${colors.blackTransparent.dark}`,
    light: `-4px 4px 4px ${colors.blackTransparent.light}`,
    normal: `-4px 4px 4px ${colors.blackTransparent.normal}`
  },
  mercury: {
    dark: `0px 2px 4px ${colors.mercury.dark}`,
    light: `0px 2px 4px ${colors.mercury.light}`,
    normal: `0px 2px 4px ${colors.mercury.normal}`
  },
  transparent: {
    dark: `0px 0px 0px ${colors.transparent.dark}`,
    light: `0px 0px 0px ${colors.transparent.light}`,
    normal: `0px 0px 0px ${colors.transparent.normal}`
  },
  blueTransparent: {
    dark: `-4px 4px 4px ${colors.blueTransparent.dark}`,
    light: `-4px 4px 4px ${colors.blueTransparent.light}`,
    normal: `-4px 4px 4px ${colors.blueTransparent.normal}`
  },
  autoComplete: {
    dark: `0 0 0 1000px ${colors.transparent.dark}`,
    light: `0 0 0 1000px ${colors.transparent.light}`,
    normal: `0 0 0 1000px ${colors.transparent.normal}`
  }
};
